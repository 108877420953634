import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AnonymousAccessService } from '@campaign/services/anonymous-access.service';
import { AuthenticationService } from '@auth/services/authentication.service';
import { Observable } from 'rxjs';

export const RETURN_URL_PARAM = 'returnUrl';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  private authenticationService = inject(AuthenticationService);
  private router = inject(Router);
  private anonymousService = inject(AnonymousAccessService);

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.anonymousService.isAnonymousUser() == null && this.anonymousService.setAnonymousUser(false);
    if (!this.authenticationService.isLoggedIn) {
      this.router.navigate(['/login'], { queryParams: { [RETURN_URL_PARAM]: state.url } });
    }
    return this.authenticationService.isLoggedIn;
  }
}
